<template>
  <div>
    <div class="user-info">
      <div class="user-header">
        <img
          class="avatar"
          src="https://img.glztj.com/images/202211/358155271917637.jpg"
          alt=""
        />
        <div>
          <div class="user-name">用户1</div>
          <div class="phone">15312345678</div>
        </div>
      </div>
      <div class="company">所在公司：广州领航技术科技有限公司</div>
      <div class="date">有效日期：2024-12-31</div>
    </div>

    <div class="main">
      <van-empty
        v-if="showEmpty"
        description="查询不到团检套餐，请联系企业确认您是否在本期体检名单中或者拨打400-9003732转2转0咨询"
      />
      <template v-else>
        <list :userInfo="{}" :productList="productList"></list>
      </template>
    </div>
  </div>
</template>

<script>
import list from "@/views/components/group-profile/list.vue";

import { getDemoProducts } from "@/api";
export default {
  components: {
    list,
  },
  data() {
    return {
      productList: [],
      showEmpty: false,
    };
  },
  created() {
    this.$store.commit(
      "setHospitalId",
      this.$route.query.hospitalId || localStorage.getItem("hospitalId")
    );
    this.getProducts();
  },
  mounted() {
    this.$store.commit("setTitle", "单位体检");
    this.$setTitle("单位体检");
  },
  methods: {
    async getProducts() {
      const res = await getDemoProducts({
        code: this.$route.query.code,
      });
      if (res.success) {
        if (res?.data?.length) {
          this.productList = res.data;
        } else {
          this.showEmpty = true;
        }
      }
    },
  },
};
</script>


<style>
html body {
  background-color: #f4f4f4;
}
.van-empty__description {
  text-align: center;
}
</style>
<style scoped>
.user-info {
  position: relative;
  padding: 25px 20px 16px 20px;
  background-color: #fff;
  background: url("../../assets/group-profile-bg.png") 100% 100% no-repeat;
  background-size: 100% 100%;
}

.user-header {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 10px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
}

.user-name {
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
}

.phone {
  margin-top: 5px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
}

.company,
.date {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #475266;
}

.company {
  margin-top: 15px;
}
.date {
  margin-top: 5px;
}

.main {
  padding: 0 10px;
}

.recommend-button-container {
  position: relative;
  height: calc(80px + constant(safe-area-inset-bottom));
  height: calc(80px + env(safe-area-inset-bottom));
}

.recommend-button-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: #fff;
  padding: 10px;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
}

.recommend-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: #00c4b3;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
}

.icon-star {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}
</style>