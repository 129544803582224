<template>
  <div>
    <div class="header-title">可预约套餐</div>
    <div
      class="card-item"
      v-for="(item, index) in productList"
      :key="index"
      @click="onItemClick(item)"
    >
      <div class="flex">
        <div class="img-container">
          <img
            v-if="item.listImageUrl"
            :src="item.listImageUrl | filterImg"
            alt=""
          />
          <div class="sex-key" v-if="item.dictSexKey === '1'">限男性</div>
          <div class="sex-key" v-else-if="item.dictSexKey === '2'">限女性</div>
          <div class="sex-key" v-else-if="item.dictSexKey === '0'">
            男女通用
          </div>
        </div>
        <div class="card-info flex-column">
          <div class="card-title bold">{{ item.productName }}</div>
          <div class="card-types">
            <div>
              <span>{{ item.projectItemCount }}个检查项目</span>
              <span v-if="item.diseaseItemCount"
                >筛查{{ item.diseaseItemCount }}个疾病</span
              >
            </div>
            <span v-for="(key, index) in item.sellingPoints" :key="index">{{
              key
            }}</span>
          </div>
          <div class="card-price">
            <span>
              <template v-if="userInfo.examMethod === 2">
                <span style="font-size: 12px" class="sale-price">￥</span>
                <span class="sale-price">{{ item.price }}</span>
              </template>
              <span class="appointmentCount">已约{{ item.salesVolume }} </span>
            </span>
            <span
              class="appointment-button"
              :class="getButtonStyle(item).className"
              >{{ getButtonStyle(item).text }}</span
            >
          </div>
        </div>
      </div>
      <div class="footer">{{ item.hospitalName }}</div>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
    usedProductId: {
      type: Number,
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  methods: {
    getButtonStyle(item) {
      const userInfo = this.$props.userInfo;
      let style = {};
      if (userInfo.isUsed) {
        if (userInfo.usedProductId === item.productId) {
          style = {
            text: this.getButtonText(userInfo.orderDetailStatus),
            className: "primary-button",
          };
        } else {
          style = {
            text: "预约",
            className: "disabled-button",
          };
        }
      } else {
        style = {
          text: "预约",
          className: "primary-button",
        };
      }
      return style;
    },
    getButtonText(status) {
      switch (status) {
        case 0:
          return "去支付";
        case 1:
          return "已支付";
        case 2:
          return "已预约";
        case 3:
          return "已预约";
        case 4:
          return "退款中";
        case 5:
          return "退款完成";
        case 6:
          return "已完成";
        case 8:
          return "已关闭";
      }
    },
    onItemClick(item) {
      const userInfo = this.$props.userInfo;
      if (userInfo.isUsed) {
        if (userInfo.usedProductId !== item.productId) {
          this.$toast("您已有预约订单");
        } else {
          this.$router.push("/order-list");
        }
      } else {
        this.$router.push("/good-detail/" + item.hospitalProductId + '?from=group');
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.header-title {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
  padding: 0 10px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}
.card-item {
  width: 100%;
  padding: 6px 10px 0 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  .img-container {
    position: relative;
    width: 80px;
    height: 95px;
    border-radius: 8px;
    margin-right: 10px;
  }

  .img img {
    width: 70px;
    height: 70px;
    border-radius: 12px;
  }

  .sex-key {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    background: linear-gradient(220deg, #ee7c3c 3%, #ff3d00 100%);
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    height: 15px;
    color: #fff;
  }
  .card-title {
    color: #333;
    font-size: 15px;
    line-height: 18px;
  }
  .card-info {
    margin-top: 16px;
    flex: 1;
  }
  .card-types {
    span {
      padding: 3px 4px;
      margin: 4px 5px 0 0;
      color: #ffb470;
      background: rgba(255, 185, 16, 0.1);
      border-radius: 4px;
      font-weight: bold;
      font-size: 12px;
    }
    div {
      font-size: 12px;
      margin: 6px 0 8px;
      span:nth-child(-n + 2) {
        font-weight: bold;
        color: #0091ff;
        background: rgba(60, 196, 255, 0.1);
        font-size: 12px;
      }
    }
  }
  .card-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .sale-price {
      font-size: 20px;
      color: #ff3d00;
      font-weight: bold;
    }
    .appointmentCount {
      margin-left: 10px;
      font-size: 13px;
      color: #878d99;
    }
    .primary-button,
    .disabled-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 30px;
      font-size: 14px;
      font-weight: bold;
      background: rgba(0, 196, 179, 0.1);
      color: #00c4b3;
      border: 1px solid#00c4b3;
      border-radius: 15px;
    }
    .disabled-button {
      color: #ccc;
      background-color: #fff;
      border: 1px solid #edeff0;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  height: 48px;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  border-top: 1px solid #edeff0;
}
</style>